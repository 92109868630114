import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./css/404.styl"

const NotFoundPage = ({ location }) => {
  const intl = useIntl()
  const language = intl.locale
  return (
    <Layout location={location}>
      {/* <SEO title="404: Not found" /> */}
      <SEO location={location} lang={intl.locale} title="Not found" />
      <section className="not_found component">
        <h1>Not Found</h1>
        <p>
          {language === "en"
            ? "You just hit a route that doesn't exist..."
            : "申し訳ありません。お探しのページが見つかりません。。"}
        </p>
      </section>
    </Layout>
  )
}

export default NotFoundPage
